import React, { useState, useEffect, useRef } from "react";
import { Button } from "primereact/button";
import { InputText } from "primereact/inputtext";
import { Card } from "primereact/card";
import { Dropdown } from "primereact/dropdown";
import HomeLookupService from "../api/services/HomeLookupService";
import { useForm, Controller } from "react-hook-form";
import { LANGUAGE, USER_LEVEL } from "../../utilities/constants/ITMISConstansts";
import OrganizationService from "../../security/api/services/OrganizationService";
// import RoleService from "../../security/api/services/RoleService";
import i18n from "../../il8n/il8n";
import AddressService from "../../security/api/services/AddressService";
import UserService from "../../security/api/services/UserService";
import { Toast } from "primereact/toast";
import { trackPromise } from "react-promise-tracker";
import { t } from "i18next";

const HomeLookup = () => {
    const [provincesList, setProvincesList] = useState([]);
    const [districtsList, setDistrictList] = useState([]);
    const [municipalitiesList, setMunicipalitiesList] = useState([]);
    const [userLevel, setUserLevel] = useState();
    const [TypeList, setTypeList] = useState([
        {
            value: "ShelterHome",
            nameEng: "Shelter Home",
            nameNep: "सुरक्षित आवास गृह",

        },
        {
            value: "ServiceCenter",
            nameEng: "Service Center",
            nameNep: "सेवा केन्द्र",

        },
        {
            value: "ChildCareHome",
            nameEng: "Child Care Home",
            nameNep: "बाल सेवा गृह",

        },
        {
            value: "ChildCorrectionHome",
            nameEng: "Juvenile Reform Home",
            nameNep: "बाल सुधार गृह",

        },
    ]);


    const [buttonLabel, setButtonLabel] = useState("Create");
    const toast = useRef(null);

    useEffect(() => {
        AddressService.getAllProvinces().then((response) => {
            setProvincesList(response.data.data);
        });

        let organizationAddress = {};
        OrganizationService.getLoggedInUserOrganizaitonDetails().then((response) => {
            console.log("response.data.data.name", response.data);
            organizationAddress = response.data.data.organizationAddress;
            UserService.getUserLevel().then((response) => {
                if (response.data.data == USER_LEVEL.PROVINCE) {
                    getDistrictValue(organizationAddress.province);
                    setValue("province", parseInt(organizationAddress.province));
                } else if (response.data.data == USER_LEVEL.DISTRICT) {
                    getDistrictValue(organizationAddress.province);
                    getMunicipalitiesList(organizationAddress.district);
                    setValue("province", parseInt(organizationAddress.province));
                    setValue("district", parseInt(organizationAddress.district));
                } else if (response.data.data == USER_LEVEL.LOCAL_LEVEL || response.data.data == USER_LEVEL.LOCAL_LEVEL_VERIFIER) {
                    getDistrictValue(organizationAddress.province);
                    getMunicipalitiesList(organizationAddress.district);
                    setValue("province", parseInt(organizationAddress.province));
                    setValue("district", parseInt(organizationAddress.district));
                    setValue("municipality", parseInt(organizationAddress.municipality));
                }
                setUserLevel(response.data.data);
            });
        });
    }, []);

    const {
        control,
        formState: { errors },
        handleSubmit,
        setValue,
    } = useForm({
        province: "",
    });
    const getFormErrorMessage = (name) => {
        return errors[name] && <small className="p-error">{errors[name].message}</small>;
    };

    const onSubmit = (data) => {
        console.log("Data", data);
        trackPromise(
            HomeLookupService.save(data)
                .then((response) => {
                    if (response.status === 200) {
                        toast.current.show({
                            severity: "success",
                            summary: "Success Message",
                            detail: "Save Successful",
                            life: 3000,
                        });
                        window.location.reload(false);
                    } else {
                        toast.current.show({
                            severity: "error",
                            summary: "Error Message",
                            detail: "Save UnSuccessful",
                            life: 3000,
                        });
                    }
                })
                .catch(function (error) {
                    if (error.response) {
                        console.log(error.response.data.error_description);
                        toast.current.show({
                            severity: "error",
                            summary: "Error Message",
                            detail: error.response.data.error_description,
                            life: 3000,
                        });
                    } else if (error.request) {
                        console.log(error.request);
                        toast.current.show({
                            severity: "error",
                            summary: "Error Message",
                            detail: "Network Error",
                            life: 3000,
                        });
                    } else {
                        // Something happened in setting up the request that triggered an Error
                        console.log("Error", error.message);
                    }
                })
        );
    };

    const getDistrictValue = (provinceId) => {
        AddressService.getAllDistrictsByProvinceId(provinceId).then((response) => {
            setDistrictList(response.data.data);
        });
    };

    const getMunicipalitiesList = (districtId) => {
        AddressService.getAllMunicipalitiessByDistrictId(districtId).then((response) => {
            setMunicipalitiesList(response.data.data);
        });
    };

    return (
        <>
            <div style={{ marginLeft: "2.5rem" }}>
                <Toast ref={toast} />
                <Card className="p-mb-1" style={{ borderRadius: "8px 8px 0px 0px", background: "#f7f7f8" }}>
                    <div className=" p-card-content">
                        <h4 className="p-pt-0">{t("Home Lookup")}</h4>
                    </div>
                </Card>

                <Card className="p-mt-0" style={{ overflowY: "auto" }}>
                    <div>
                        <div className="" style={{ float: "left" }}>

                            <form className="p-grid p-fluid p-mt-3" onSubmit={handleSubmit(onSubmit)}>

                                <div className="p-field p-col-12 p-md-12 ">

                                    <div class="p-field p-col-12 p-md-4 float-left p-pr-0">
                                        <div class="p-field p-col-8 p-md-8 float-left main-label">{t("province")} </div>
                                        <Controller
                                            name="province"
                                            control={control}
                                            rules={{ required: "Province is required." }}
                                            render={({ field }) => (
                                                <Dropdown
                                                    filter
                                                    id={field.name}
                                                    value={field.value}
                                                    placeholder={"Select Province"}
                                                    onChange={(e) => {
                                                        field.onChange(e.value);
                                                        getDistrictValue(e.value);
                                                    }}
                                                    style={{ width: "100%" }}
                                                    options={provincesList}
                                                    optionLabel={i18n.language == LANGUAGE.ENGLISH ? "provinceDescEng" : "provinceDescNep"}
                                                    optionValue="id"
                                                    disabled={
                                                        userLevel == USER_LEVEL.PROVINCE ||
                                                        userLevel == USER_LEVEL.DISTRICT ||
                                                        userLevel == USER_LEVEL.LOCAL_LEVEL ||
                                                        userLevel == USER_LEVEL.LOCAL_LEVEL_VERIFIER
                                                    }
                                                />
                                            )}
                                        />
                                        {getFormErrorMessage("province")}
                                    </div>

                                    <div class="p-field p-col-12 p-md-4 float-left p-pr-0">
                                        <div class="p-field p-col-8 p-md-8 float-left main-label">{t("district")} </div>
                                        <Controller
                                            name="district"
                                            control={control}
                                            // rules={{ required: "District is required." }}
                                            render={({ field }) => (
                                                <Dropdown
                                                    filter
                                                    id={field.name}
                                                    value={field.value}
                                                    placeholder={"Select District"}
                                                    onChange={(e) => {
                                                        field.onChange(e.value);
                                                        getMunicipalitiesList(e.value);
                                                    }}
                                                    style={{ width: "100%" }}
                                                    options={districtsList}
                                                    optionLabel={i18n.language == LANGUAGE.ENGLISH ? "districtDescEng" : "districtDescNep"}
                                                    optionValue="id"
                                                    disabled={userLevel == USER_LEVEL.DISTRICT || userLevel == USER_LEVEL.LOCAL_LEVEL ||
                                                        userLevel == USER_LEVEL.LOCAL_LEVEL_VERIFIER}
                                                />
                                            )}
                                        />
                                        {getFormErrorMessage("district")}
                                    </div>

                                    <div className="p-field p-col-12 p-md-4 float-left p-pr-0">
                                        <div class="p-field p-col-8 p-md-8 float-left main-label">{t("municipality")}:</div>
                                        <Controller
                                            name="municipality"
                                            control={control}
                                            //  rules={{ required: "Municipal is required." }}
                                            render={({ field }) => (
                                                <Dropdown
                                                    filter
                                                    id={field.name}
                                                    value={field.value}
                                                    placeholder={"Select Municipality"}
                                                    onChange={(e) => {
                                                        field.onChange(e.value);
                                                    }}
                                                    style={{ width: "100%" }}
                                                    options={municipalitiesList}
                                                    optionLabel={i18n.language == LANGUAGE.ENGLISH ? "municipalityDescEng" : "municipalityDescNep"}
                                                    optionValue="id"
                                                    disabled={userLevel == USER_LEVEL.LOCAL_LEVEL ||
                                                        userLevel == USER_LEVEL.LOCAL_LEVEL_VERIFIER}
                                                />
                                            )}
                                        />
                                        {getFormErrorMessage("municipality")}
                                    </div>
                                </div>

                                <div className="p-field p-col-12 p-md-6 float-left">
                                    <div class="p-field p-col-12 p-md-12 float-left main-label">
                                        {t("homeType")} :
                                    </div>
                                    <div class="p-field p-col-12 p-md-12 float-left">
                                        <Controller
                                            name="homeType"
                                            control={control}
                                            rules={{ required: "Home Type is required." }}
                                            render={({ field }) => (
                                                <Dropdown
                                                    filter
                                                    id={field.name}
                                                    value={field.value}
                                                    placeholder={t("select")}
                                                    onChange={(e) => {
                                                        field.onChange(e.value);
                                                    }}
                                                    style={{ width: "100%" }}
                                                    options={TypeList}
                                                    optionLabel={i18n.language == LANGUAGE.ENGLISH ? "nameEng" : "nameNep"}
                                                    optionValue="value"
                                                />
                                            )}
                                        />
                                        {getFormErrorMessage("homeType")}
                                    </div>
                                </div>

                                <div className="p-field p-col-12 p-md-12 ">

                                    <div class="p-field p-col-8 p-md-6 float-left p-pr-0">
                                        <div class="p-field p-col-12 p-md-6 float-left main-label">{t("homeNameEng")} :</div>
                                        <Controller
                                            name="nameEng"
                                            control={control}
                                            rules={{ required: "nameEng is required." }}
                                            render={({ field }) => (
                                                <InputText
                                                    id={field.name}
                                                    {...field}
                                                    autoFocus
                                                    className="rounded-input p-mb-1"
                                                />
                                            )}
                                        />
                                        {getFormErrorMessage("nameEng")}
                                    </div>
                                    <div class="p-field p-col-8 p-md-6 float-left p-pr-0">
                                        <div class="p-field p-col-12 p-md-6 float-left main-label">{t("homeNameNep")} : </div>
                                        <Controller
                                            name="nameNep"
                                            control={control}
                                            rules={{ required: "nameNep is required." }}
                                            render={({ field }) => (
                                                <InputText
                                                    id={field.name}
                                                    {...field}
                                                    autoFocus
                                                    className="rounded-input p-mb-1"
                                                />
                                            )}
                                        />
                                        {getFormErrorMessage("nameNep")}
                                    </div>
                                </div>

                                <div className="p-field p-col-12 p-md-12 ">
                                    <div class="p-field p-col-10 p-md-10 float-left"></div>

                                    <div class="p-field p-col-2 p-md-2 float-right">
                                        <Button
                                            className="p-button-sm pull-right submitBtn"
                                            label={t("submit")}
                                            style={{
                                                background: "#4e70ae",
                                                color: "#FFF",
                                                // width: "100%",
                                                textAlign: "center",
                                            }}
                                        />
                                    </div>
                                </div>
                            </form>
                        </div>

                    </div>


                    <div style={{ clear: "both" }}></div>
                </Card>
            </div>
        </>
    );
};
export default HomeLookup;
