import React, { Component, useState, useRef, useEffect } from "react";
import { Accordion, AccordionTab } from "primereact/accordion";
import NotificationService from "../api/services/NotificationService";
import { Badge } from "primereact/badge";
import { Dialog } from "primereact/dialog";
import { Button } from "primereact/button";
import UserService from "../../security/api/services/UserService";
import { USER_LEVEL } from "../../utilities/constants/ITMISConstansts";
import { t } from "i18next";
import MUIDataTable from "mui-datatables";
import { ThemeProvider } from "@mui/material/styles";
import { createTheme } from "@mui/material/styles";
import OrganizationService from "../../security/api/services/OrganizationService";
import { AirlineSeatLegroomReducedOutlined } from "@mui/icons-material";

function Notification() {
  // data = {subscriberId: 0, } notification:[{header:"No Head", content:"Nothing to show."}]
  const [popUp, setPopUp] = useState(false);
  const [synchronizeList, setSynchronizeList] = useState([]);
  const [correctionList, setCorrectionList] = useState([]);
  const [verificationList, setVerificationList] = useState([]);
  const [revertList, setRevertList] = useState([]);
  const [count, setCount] = useState(0);
  const [showBadge, setShowBadge] = useState(false);
  const [synchronize, setSynchronize] = useState(false);
  const [homeNotification, setHomeNotification] = useState(false);
  const [homeVerification, setHomeVerification] = useState(false);

  function ch() {
    setPopUp(!popUp);
  }

  const options = {
    filter: true,
    print: false,
    filterType: "dropdown",
    responsive: "vertical",
    fixedSelectColumn: false,
    selectableRows: "none",
    onRowClick: (rowData, rowMeta) => {
      console.log("rowData", rowData, rowMeta);
    },
  };

  useEffect(() => {
    UserService.getUserLevel().then((response) => {
      if (response.data.data === USER_LEVEL.LOCAL_LEVEL) {
        setShowBadge(true);
        NotificationService.getRevertedList().then((response) => {
          setSynchronize(false);
          console.log("reverted list>>>>>>>>", response);
          if (response.data.data.length) {
            setRevertList(response.data.data);
            setCount(response.data.data.length);
          }
        });
      } else if (response.data.data === USER_LEVEL.LOCAL_LEVEL_VERIFIER) {
        setShowBadge(true);
        NotificationService.getToBeSynchronizedList().then((response) => {
          console.log("synchronized list>>>>>>>>", response);
          setSynchronize(true);
          let len = 0;
          if (response.data.data.length) {
            setSynchronizeList(response.data.data);
            len = response.data.data.length;
          }
          NotificationService.getRequestForVerificationList().then((response) => {
            console.log("getRequestForVerificationList>>>>>>>>", response);
            if (response.data.data.length) {
              setVerificationList(response.data.data);
              // if (len = 0) {
              //   setCount(response.data.data.length);
              // } else {
              let len2 = response.data.data.length;
              let total = len + len2;
              setCount(total);
              //}
            } else {
              setCount(len);
            }
          });
        });
      } else if (response.data.data === USER_LEVEL.SOCIAL_SERVICE) {
        setShowBadge(true);
        OrganizationService.getLoggedInUserHomeDetails().then((response) => {
          let homeDetail = response.data.data;
          if (homeDetail.homeId) {
            NotificationService.sendBackForCorrectionList(homeDetail.homeId).then((response) => {
              console.log("sendBackForCorrectionList>>>>>>>>", response);
              setHomeNotification(true);
              if (response.data.data.length) {
                setCorrectionList(response.data.data);
                setCount(response.data.data.length);
              }
            });
          }
        });
      } else {
        setShowBadge(false);
        setCount(0);
        setSynchronize(false);
        setHomeNotification(false);
      }
    });
  }, []);

  const synchronizeColumns = [
    {
      name: "module",
      label: t("module"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value;
        },
      },
    },
    {
      name: "module_nep",
      label: t("moduleNep"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value;
        },
      },
    },
    {
      name: "fiscal_year",
      label: t("fiscalYear"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value;
        },
      },
    },
    {
      name: "quarter",
      label: t("quarter"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value === '1') {
            return "Shrawan-Ashwin";
          } else if (value === '2') {
            return "Kartik-Poush";
          } else if (value === '3') {
            return "Magh-Chaitra";
          } else {
            return "Baishak-Ashad";
          }
        }
      },
    },

    {
      name: "entry_date",
      label: t("entryDate"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value;
        },
      },
    }
  ];

  const revertColumns = [
    {
      name: "module",
      label: t("module"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value;
        },
      },
    },
    {
      name: "module_nep",
      label: t("moduleNep"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value;
        },
      },
    },
    {
      name: "fiscal_year",
      label: t("fiscalYear"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value;
        },
      },
    },
    {
      name: "quarter",
      label: t("quarter"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          if (value === '1') {
            return "Shrawan-Ashwin";
          } else if (value === '2') {
            return "Kartik-Poush";
          } else if (value === '3') {
            return "Magh-Chaitra";
          } else {
            return "Baishak-Ashad";
          }
        }
      },
    },
    {
      name: "entry_date",
      label: t("entryDate"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value;
        },
      },
    },
    {
      name: "remarks",
      label: t("remarks"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value;
        },
      },
    }
  ];
  const verificationColumns = [
    {
      name: "home_type",
      label: t("homeType"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value;
        },
      },
    },
    {
      name: "home_name_eng",
      label: t("homeNameEng"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value;
        },
      },
    },
    {
      name: "home_name_nep",
      label: t("homeNameNep"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value;
        },
      },
    },
    {
      name: "personal_code",
      label: t("personalCode"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value;
        },
      },
    },
  ];
  const correctionColumns = [
    {
      name: "home_type",
      label: t("homeType"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value;
        },
      },
    },
    {
      name: "personal_code",
      label: t("personalCode"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value;
        },
      },
    },
    {
      name: "status",
      label: t("status"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value;
        },
      },
    },
    {
      name: "reason",
      label: t("reason"),
      options: {
        customBodyRender: (value, tableMeta, updateValue) => {
          return value;
        },
      },
    }
  ];

  return (
    <div>
      {showBadge ? (
        <div onClick={() => ch()}>
          <i
            className="pi pi-bell  p-overlay-badge"
            style={{ fontSize: "1.5em", marginRight: "0%" }}
          >
            {count > 0 ? (
              <Badge
                value={count}
                style={{ marginTop: "-10%", float: "right" }}
              ></Badge>
            ) : null}
          </i>
        </div>
      ) : (
        ""
      )}

      <Dialog
        header={t("notification")}
        visible={popUp}
        modal
        style={{ width: "80vw" }}
        onHide={ch}
        position="top"
      >
        <ThemeProvider
          theme={createTheme({
            overrides: {
              MUIDataTableToolbar: { root: { display: "none" } },
            },
          })}
        >
          {synchronize ? <>
            <MUIDataTable
              title={t("synchronizeDataOfModules")}
              data={synchronizeList}
              columns={synchronizeColumns}
              options={options}
            />
            <div style={{ margin: "70px" }}></div>
            <MUIDataTable
              title={t("verificationOfHomeDetails")}
              data={verificationList}
              columns={verificationColumns}
              options={options}
            />
          </>
            :
            <MUIDataTable
              title={t("revertedDataOfModules")}
              data={revertList}
              columns={revertColumns}
              options={options}
            />
          }
          {homeNotification ? <MUIDataTable
            title={t("needCorrection")}
            data={correctionList}
            columns={correctionColumns}
            options={options}
          />
            :
            <></>
          }
        </ThemeProvider>
      </Dialog>
    </div>
  );
}

export default Notification;
