import React, { useState, useRef, useEffect } from "react";
import { useForm, Controller } from "react-hook-form";

import { Button } from "primereact/button";
import { InputNumber } from "primereact/inputnumber";
import { Dropdown } from "primereact/dropdown";
import { Card } from "primereact/card";
import { classNames } from "primereact/utils";
import { Toast } from "primereact/toast";
import { useHistory } from "react-router";

import FiscalQuarter from "../../utilities/components/FiscalQuarter";
import Source from "../../utilities/components/Source";
import { useTranslation } from "react-i18next";
import ChildCorrectionHomeService from "../api/services/ChildCorrectionHomeService";
import OrganizationService from "../../security/api/services/OrganizationService";
import Organization from "../../utilities/components/Organization";
import { trackPromise } from "react-promise-tracker";
import UserService from "../../security/api/services/UserService";
import { USER_LEVEL } from "../../utilities/constants/ITMISConstansts";

function JuvenileReformHomeIndicator() {
  const { t } = useTranslation();
  const history = useHistory();

  const [fiscalYear, setFiscalYear] = useState("");
  const [quarter, setQuarter] = useState("");
  const [childCorrectionHomeId, setChildCorrectionHomeId] = useState();

  const [update, setUpdate] = useState("No");
  const [showBtn, setShowBtn] = useState("Yes");
  const [hideBtn, setHideBtn] = useState("No");
  const [enableForm, setEnableForm] = useState(true);
  const [homeRegisteredModal, setHomeRegisteredModal] = useState(true);
  const [organization, setOrganization] = useState("");

  const [localLevelVerifier, setLocalLevelVerifier] = useState(false);

  const {
    control,
    formState: { errors },
    handleSubmit,
    reset,
    setValue,
    getValues
  } = useForm();
  const toast = useRef(null);
  const getFormErrorMessage = (name) => {
    return (
      errors[name] && <small className="p-error">{errors[name].message}</small>
    );
  };

  useEffect(() => {
    UserService.getUserLevel().then((response) => {
      if (response.data.data === USER_LEVEL.LOCAL_LEVEL || response.data.data === USER_LEVEL.SOCIAL_SERVICE) {
        setHideBtn("No");
        setLocalLevelVerifier(false);
      } else if (response.data.data === USER_LEVEL.LOCAL_LEVEL_VERIFIER) {
        setHideBtn("Yes");
        setLocalLevelVerifier(true);
      } else {
        setLocalLevelVerifier(false);
        setHideBtn("Yes");
      }
    });
  }, []);

  //To save data
  const saveData = (e) => {
    e.preventDefault();
    let data = getValues();
    data.fiscalYear = fiscalYear;
    data.quarter = quarter;
    data.status = "Save";
    console.log("data", data);

    if (update === "No") {
      //trackPromise is used for loading
      trackPromise(
        ChildCorrectionHomeService.saveData(data).then((response) => {
          console.log("response", response);
          if (response.status === 200) {
            toast.current.show({
              severity: "success",
              summary: "Success Message",
              detail: "Save Successful",
              life: 3000,
            });
            window.location.reload(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error Message",
              detail: "Save UnSuccessful",
              life: 3000,
            });
          }
        })
          .catch((error) => {
            // We want to handle globally
            error.handleGlobally && error.handleGlobally();
            toast.current.show({
              severity: "error",
              summary: "Error Message",
              detail: error.response.data.msg,
              life: 3000,
            });
          })
      );
    } else {
      data.childCorrectionHomeId = childCorrectionHomeId;
      trackPromise(
        ChildCorrectionHomeService.updateData(data).then((response) => {
          console.log("response", response);
          if (response.status === 200) {
            toast.current.show({
              severity: "success",
              summary: "Success Message",
              detail: "Update Successful",
              life: 3000,
            });
            window.location.reload(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error Message",
              detail: "Update UnSuccessful",
              life: 3000,
            });
          }
        })
          .catch((error) => {
            // We want to handle globally
            error.handleGlobally && error.handleGlobally();
            toast.current.show({
              severity: "error",
              summary: "Error Message",
              detail: error.response.data.msg,
              life: 3000,
            });
          })
      );
    }
  };

  //To update data
  const submitData = (data) => {
    data.fiscalYear = fiscalYear;
    data.quarter = quarter;
    data.status = "Submit";
    console.log("data", data);

    if (update === "No") {
      //trackPromise is used for loading
      trackPromise(
        ChildCorrectionHomeService.saveData(data).then((response) => {
          console.log("response", response);
          if (response.status === 200) {
            toast.current.show({
              severity: "success",
              summary: "Success Message",
              detail: "Submit Successful",
              life: 3000,
            });
            window.location.reload(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error Message",
              detail: "Submit UnSuccessful",
              life: 3000,
            });
          }
        })
          .catch((error) => {
            // We want to handle globally
            error.handleGlobally && error.handleGlobally();
            toast.current.show({
              severity: "error",
              summary: "Error Message",
              detail: error.response.data.msg,
              life: 3000,
            });
          })
      );
    } else {
      data.childCorrectionHomeId = childCorrectionHomeId;
      trackPromise(
        ChildCorrectionHomeService.updateData(data).then((response) => {
          console.log("response", response);
          if (response.status === 200) {
            toast.current.show({
              severity: "success",
              summary: "Success Message",
              detail: "Submit Successful",
              life: 3000,
            });
            window.location.reload(false);
          } else {
            toast.current.show({
              severity: "error",
              summary: "Error Message",
              detail: "Submit UnSuccessful",
              life: 3000,
            });
          }
        })
          .catch((error) => {
            // We want to handle globally
            error.handleGlobally && error.handleGlobally();
            toast.current.show({
              severity: "error",
              summary: "Error Message",
              detail: error.response.data.msg,
              life: 3000,
            });
          })
      );
    }
  };

  const handleFiscalYear = (fiscalYearVal) => {
    console.log("fiscal year  ", fiscalYearVal);
    setFiscalYear(fiscalYearVal);
    console.log("quarter in fiscalYear", quarter);
    if (quarter !== "" && organization != "") {
      getListByOrganization(fiscalYearVal, quarter, organization);
    } else if (quarter !== "") {
      getListByFiscalYearAndQuarter(fiscalYearVal, quarter);
    }
  };

  const handleQuarter = (quarterVal) => {
    console.log("quarter  ", quarterVal);
    console.log("fiscalYear in quarter", fiscalYear);
    console.log("iin organization", organization);
    setQuarter(quarterVal);
    if (fiscalYear !== "" && organization != "") {
      getListByOrganization(fiscalYear, quarterVal, organization);
    } else if (fiscalYear !== "") {
      getListByFiscalYearAndQuarter(fiscalYear, quarterVal);
    }
  };

  const getListByFiscalYearAndQuarter = (fiscalYear, quarter) => {
    trackPromise(
      ChildCorrectionHomeService.getListByFiscalYearAndQuarter(
        fiscalYear,
        quarter
      ).then((response) => {
        console.log("response", response.data);
        if (response.data) {
          setFiscalYear(response.data.fiscalYear);
          setQuarter(response.data.quarter);
          setChildCorrectionHomeId(response.data.childCorrectionHomeId);
          reset({
            totChildCorrectionHome: response.data.totChildCorrectionHome,
            totChildCorrectionHomeSource:
              response.data.totChildCorrectionHomeSource[0] != "" &&
                response.data.totChildCorrectionHomeSource != ""
                ? response.data.totChildCorrectionHomeSource
                : null,
            boysReceivingServicesInCch:
              response.data.boysReceivingServicesInCch,
            othersReceivingServicesInCch:
              response.data.othersReceivingServicesInCch,
            girlsReceivingServicesInCch:
              response.data.girlsReceivingServicesInCch,

            khasAryaReceivingServicesInCch: response.data.khasAryaReceivingServicesInCch,
            hillJanjatiReceivingServicesInCch: response.data.hillJanjatiReceivingServicesInCch,
            tharuReceivingServicesInCch: response.data.tharuReceivingServicesInCch,
            hillDalitReceivingServicesInCch: response.data.hillDalitReceivingServicesInCch,
            teraiJanjatiReceivingServicesInCch: response.data.teraiJanjatiReceivingServicesInCch,
            madhesiReceivingServicesInCch: response.data.madhesiReceivingServicesInCch,
            teraiDalitReceivingServicesInCch: response.data.teraiDalitReceivingServicesInCch,
            muslimReceivingServicesInCch: response.data.muslimReceivingServicesInCch,
            otherCasteReceivingServicesInCch:
              response.data.otherCasteReceivingServicesInCch,

            childReceivingServicesInCchSource:
              response.data.childReceivingServicesInCchSource[0] != "" &&
                response.data.childReceivingServicesInCchSource != ""
                ? response.data.childReceivingServicesInCchSource
                : null,
            boysRehabilitatedFromCch: response.data.boysRehabilitatedFromCch,
            girlsRehabilitatedFromCch: response.data.girlsRehabilitatedFromCch,
            othersRehabilitatedFromCch:
              response.data.othersRehabilitatedFromCch,
            rehabilitatedFromHome: response.data.rehabilitatedFromHome,
            rehabilitatedFromOjt: response.data.rehabilitatedFromOjt,
            rehabilitatedFromOutOfCommunity:
              response.data.rehabilitatedFromOutOfCommunity,
            rehabilitatedFromAdoption: response.data.rehabilitatedFromAdoption,
            rehabilitatedFromFosterHome:
              response.data.rehabilitatedFromFosterHome,
            childRehabilitatedSource:
              response.data.childRehabilitatedSource[0] != "" &&
                response.data.childRehabilitatedSource != ""
                ? response.data.childRehabilitatedSource
                : null,
            budgetForChildCorrectionHome:
              response.data.budgetForChildCorrectionHome,
            budgetForChildCorrectionHomeSource:
              response.data.budgetForChildCorrectionHomeSource,
            budgetForChildCorrectionHomeSource:
              response.data.budgetForChildCorrectionHomeSource[0] != "" &&
                response.data.budgetForChildCorrectionHomeSource != ""
                ? response.data.budgetForChildCorrectionHomeSource
                : null,

            totChildCorrectionHomeSourceOthers:
              response.data.totChildCorrectionHomeSourceOthers,
            childReceivingServicesInCchSourceOthers:
              response.data.childReceivingServicesInCchSourceOthers,
            childRehabilitatedSourceOthers:
              response.data.childRehabilitatedSourceOthers,
            budgetForChildCorrectionHomeSourceOthers:
              response.data.budgetForChildCorrectionHomeSourceOthers,
          });
          setUpdate("Yes");
          if (response.data.status === "Submit") {
            setShowBtn("No");
          } else {
            setShowBtn("Yes");
          }
        } else {
          console.log("no data");
          reset({
            totChildCorrectionHomeSource: [],
            childReceivingServicesInCchSource: [],
            childRehabilitatedSource: [],
            budgetForChildCorrectionHomeSource: [],
          });
          setUpdate("No");
          setShowBtn("Yes");
        }
      })
    );
  };

  const handleOrganization = (organizationId) => {
    console.log("quarter  ", quarter);
    console.log("fiscalYear in quarter", fiscalYear);
    setOrganization(organizationId);
    if (fiscalYear !== "" && quarter !== "") {
      getListByOrganization(fiscalYear, quarter, organizationId);
    }
  };

  const getListByOrganization = (fiscalYear, quarter, organization) => {
    trackPromise(
      ChildCorrectionHomeService.getListByOrganization(
        fiscalYear,
        quarter,
        organization
      ).then((response) => {
        console.log("response", response.data);
        if (response.data) {
          setFiscalYear(response.data.fiscalYear);
          setQuarter(response.data.quarter);
          setChildCorrectionHomeId(response.data.childCorrectionHomeId);
          reset({
            totChildCorrectionHome: response.data.totChildCorrectionHome,
            totChildCorrectionHomeSource:
              response.data.totChildCorrectionHomeSource[0] != "" &&
                response.data.totChildCorrectionHomeSource != ""
                ? response.data.totChildCorrectionHomeSource
                : null,
            boysReceivingServicesInCch:
              response.data.boysReceivingServicesInCch,
            othersReceivingServicesInCch:
              response.data.othersReceivingServicesInCch,
            girlsReceivingServicesInCch:
              response.data.girlsReceivingServicesInCch,

            khasAryaReceivingServicesInCch: response.data.khasAryaReceivingServicesInCch,
            hillJanjatiReceivingServicesInCch: response.data.hillJanjatiReceivingServicesInCch,
            tharuReceivingServicesInCch: response.data.tharuReceivingServicesInCch,
            hillDalitReceivingServicesInCch: response.data.hillDalitReceivingServicesInCch,
            teraiJanjatiReceivingServicesInCch: response.data.teraiJanjatiReceivingServicesInCch,
            madhesiReceivingServicesInCch: response.data.madhesiReceivingServicesInCch,
            teraiDalitReceivingServicesInCch: response.data.teraiDalitReceivingServicesInCch,
            muslimReceivingServicesInCch: response.data.muslimReceivingServicesInCch,
            otherCasteReceivingServicesInCch:
              response.data.otherCasteReceivingServicesInCch,

            childReceivingServicesInCchSource:
              response.data.childReceivingServicesInCchSource[0] != "" &&
                response.data.childReceivingServicesInCchSource != ""
                ? response.data.childReceivingServicesInCchSource
                : null,
            boysRehabilitatedFromCch: response.data.boysRehabilitatedFromCch,
            girlsRehabilitatedFromCch: response.data.girlsRehabilitatedFromCch,
            othersRehabilitatedFromCch:
              response.data.othersRehabilitatedFromCch,
            rehabilitatedFromHome: response.data.rehabilitatedFromHome,
            rehabilitatedFromOjt: response.data.rehabilitatedFromOjt,
            rehabilitatedFromOutOfCommunity:
              response.data.rehabilitatedFromOutOfCommunity,
            rehabilitatedFromAdoption: response.data.rehabilitatedFromAdoption,
            rehabilitatedFromFosterHome:
              response.data.rehabilitatedFromFosterHome,
            childRehabilitatedSource:
              response.data.childRehabilitatedSource[0] != "" &&
                response.data.childRehabilitatedSource != ""
                ? response.data.childRehabilitatedSource
                : null,
            budgetForChildCorrectionHome:
              response.data.budgetForChildCorrectionHome,
            budgetForChildCorrectionHomeSource:
              response.data.budgetForChildCorrectionHomeSource,
            budgetForChildCorrectionHomeSource:
              response.data.budgetForChildCorrectionHomeSource[0] != "" &&
                response.data.budgetForChildCorrectionHomeSource != ""
                ? response.data.budgetForChildCorrectionHomeSource
                : null,

            totChildCorrectionHomeSourceOthers:
              response.data.totChildCorrectionHomeSourceOthers,
            childReceivingServicesInCchSourceOthers:
              response.data.childReceivingServicesInCchSourceOthers,
            childRehabilitatedSourceOthers:
              response.data.childRehabilitatedSourceOthers,
            budgetForChildCorrectionHomeSourceOthers:
              response.data.budgetForChildCorrectionHomeSourceOthers,
          });
        } else {
          console.log("no data");
          reset({
            totChildCorrectionHomeSource: [],
            childReceivingServicesInCchSource: [],
            childRehabilitatedSource: [],
            budgetForChildCorrectionHomeSource: [],
          });
        }
      })
    );
  };

  const fiscalYearValidation = () => {
    if (fiscalYear === "" || quarter === "") {
      toast.current.show({
        severity: "warn",
        summary: t("selectFiscalYearQuarter"),
        life: 3000,
      });
    } else {
      setEnableForm(false);
    }
  }

  return (
    <div style={{ marginLeft: "2.5rem" }}>
      <Toast ref={toast} />
      <Card
        className="p-mb-1"
        style={{ borderRadius: "8px 8px 0px 0px", background: "#f7f7f8" }}
      >
        <div className=" p-card-content">
          <h4 className="p-pt-0">{t("JuvenileReformHomeIndicator")}</h4>
        </div>
      </Card>
      {/* <div className="p-grid p-col-12 p-md-12">
        <div className="p-field p-col-12 p-md-3">
          <Button className="box-shadow"
            style={{
              justifyContent: "center",
              background: "rgb(75 125 181)",
              color: "#FFF",
              width: '100%',
              minHeight: '30px',
              border: '4px solid rgb(208 0 0)',
            }}
            label={t("Indicator")}
            disabled
          />
        </div>
        <div className="p-field p-col-12 p-md-3">
          <Button className="box-shadow"
            style={{
              justifyContent: "center",
              background: "rgb(75 125 181)",
              color: "#FFF",
              width: '100%',
              minHeight: '30px',
              border: '4px solid rgb(208 0 0)',
            }}
            label={t("Case form")}
            onClick={() =>
              history.push("/sims/juvenial-child-home")
            }
          />
        </div>
        <div className="p-field p-col-12 p-md-3">
          <Button className="box-shadow"
            style={{
              justifyContent: "center",
              background: "rgb(75 125 181)",
              color: "#FFF",
              width: '100%',
              minHeight: '30px',
              border: '4px solid rgb(208 0 0)',
            }}
            label={t("Case form List")}
            onClick={() =>
              history.push("/sims/juvenile-child-home-list")
            }
          />
        </div>
      </div> */}


      <Card className="p-mt-0"
      // style={{ height: "72vh", overflowY: "auto" }}
      >
        <div className="p-card-content">
          <form className="p-grid p-fluid" autoComplete="off">
            <Organization submitOrganizationId={handleOrganization} />
            {hideBtn === "Yes" ? <></> : <></>}

            <FiscalQuarter
              fiscalYearValue={fiscalYear}
              handleFiscalYearState={handleFiscalYear}
              quarterValue={quarter}
              handleQuarterState={handleQuarter}
            />
            <div className="p-field p-col-12 p-md-12 ">
              <div className="p-field p-col-12 p-md-12 ">
                <hr style={{ marginTop: "5px" }}></hr>
              </div>
            </div>

            <div className="p-col-12 p-md-12 main-form" onClick={fiscalYearValidation} disabled={enableForm}>
              <div className="p-grid p-col-12 p-md-12 ">
                <div class="p-col-12 p-md-9">
                  <div className="p-field p-col-12 p-md-12 float-left main-label">
                    {t("totChildCorrectionHome")}
                    <i
                      className="pi pi-question-circle tooltip-style"
                      title={t("totChildCorrectionHomeDesc")}
                    />
                  </div>
                 <div className="p-field p-col-3 p-md-3 float-left">
                    <Controller
                      name="totChildCorrectionHome"
                      control={control}
                      autoFocus
                      render={({ field, fieldState }) => (
                        <InputNumber
                          id={field.name}
                          {...field}
                          className={classNames({
                            "p-invalid": fieldState.invalid,
                          })}
                          value={field.value}
                          onChange={(e) => {
                            field.onChange(e.value);
                            e.value === 0
                              ? setHomeRegisteredModal(false)
                              : setHomeRegisteredModal(true);
                          }}
                          min="0"
                        />
                      )}
                    />
                  </div>
                </div>
                <div className="p-col-12 p-md-3">
                  <Controller
                    name="totChildCorrectionHomeSource"
                    control={control}
                    // rules={{ required: "Source is required" }}
                    render={({ field, fieldState }) => (
                      <Source
                        id={field.name}
                        {...field}
                        className={classNames({
                          "p-invalid": fieldState.invalid,
                        })}
                        value={field.value}
                        handleSourceState={(e) => {
                          console.log("e", e);
                          field.onChange(e);
                        }}
                        sourceOtherValue={getValues("totChildCorrectionHomeSourceOthers")}
                        handleSourceOtherState={(e) => {
                          setValue("totChildCorrectionHomeSourceOthers", e);
                        }}
                      />
                    )}
                    defaultValue={[]}
                  />
                  <div className="p-col-12 p-md-12">
                    {getFormErrorMessage("totChildCorrectionHomeSource")}
                  </div>
                </div>
              </div>
              {homeRegisteredModal ? (
                <>
                  <div className="p-field p-col-12 p-md-12 ">
                    <div className="p-field p-col-12 p-md-12 ">
                      <hr
                        style={{ marginTop: "5px", borderTop: "1px dashed #cfcfcf" }}
                      ></hr>
                    </div>
                  </div>

                  <div className="p-grid p-col-12 p-md-12 ">
                    <div className="p-col-12 p-md-9">
                      <div className="p-field p-col-12 p-md-12 float-left main-label">
                        {t("totChildReceivingServicesInCch")}
                        <i
                          className="pi pi-question-circle tooltip-style"
                          title={t("totChildReceivingServicesInCchDesc")}
                        />
                      </div>
                      <div className="p-field p-col-12 p-md-12 float-left sub-label">
                        {t("byGender")}
                      </div>
                     <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("boys")}
                    </div>
                        <Controller
                          name="boysReceivingServicesInCch"
                          control={control}
                          autoFocus
                          render={({ field, fieldState }) => (
                            <InputNumber
                              id={field.name}
                              {...field}
                              className={classNames({
                                "p-invalid": fieldState.invalid,
                              })}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);
                              }}
                              min="0"
                              // placeholder={t("boys")}
                              tooltip={t("boys")}
                              tooltipOptions={{
                                position: "bottom"
                              }}
                            />
                          )}
                        />
                      </div>
                     <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("girls")}
                    </div>
                        <Controller
                          name="girlsReceivingServicesInCch"
                          control={control}
                          autoFocus
                          render={({ field, fieldState }) => (
                            <InputNumber
                              id={field.name}
                              {...field}
                              className={classNames({
                                "p-invalid": fieldState.invalid,
                              })}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);
                              }}
                              min="0"
                              // placeholder={t("girls")}
                              tooltip={t("girls")}
                              tooltipOptions={{
                                position: "bottom"
                              }}
                            />
                          )}
                        />
                      </div>
                     <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("others")}
                    </div>
                        <Controller
                          name="othersReceivingServicesInCch"
                          control={control}
                          autoFocus
                          render={({ field, fieldState }) => (
                            <InputNumber
                              id={field.name}
                              {...field}
                              className={classNames({
                                "p-invalid": fieldState.invalid,
                              })}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);
                              }}
                              min="0"
                              // placeholder={t("others")}
                              tooltip={t("others")}
                              tooltipOptions={{
                                position: "bottom"
                              }}
                            />
                          )}
                        />
                      </div>
                     <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("total")}
                    </div>
                        <Controller
                          name="totalReceivingServicesInCch"
                          control={control}
                          render={({ field, fieldState }) => (
                            <InputNumber
                              id={field.name}
                              {...field}
                              className={classNames({
                                "p-invalid": fieldState.invalid,
                              })}
                              value={(getValues("boysReceivingServicesInCch") ? getValues("boysReceivingServicesInCch") : 0)
                                + (getValues("girlsReceivingServicesInCch") ? getValues("girlsReceivingServicesInCch") : 0)
                                + (getValues("othersReceivingServicesInCch") ? getValues("othersReceivingServicesInCch") : 0)}
                              onChange={(e) => {
                                field.onChange(e.value);
                              }}
                              min="0"
                              disabled
                              tooltip={t("total")}
                              tooltipOptions={{
                                position: "bottom"
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="p-field p-col-12 p-md-12 float-left sub-label">
                        {t("byEthnicity")}
                      </div>
                     <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("khasArya")}
                    </div>
                        <Controller
                          name="khasAryaReceivingServicesInCch"
                          control={control}
                          render={({ field, fieldState }) => (
                            <InputNumber
                              id={field.name}
                              {...field}
                              className={classNames({
                                "p-invalid": fieldState.invalid,
                              })}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);
                              }}
                              min="0"
                              // placeholder={t("khasArya")}
                              tooltip={t("khasArya")}
                              tooltipOptions={{
                                position: "bottom"
                              }}
                            />
                          )}
                        />
                      </div>
                     <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("hillJanajati")}
                    </div>
                        <Controller
                          name="hillJanjatiReceivingServicesInCch"
                          control={control}
                          render={({ field, fieldState }) => (
                            <InputNumber
                              id={field.name}
                              {...field}
                              className={classNames({
                                "p-invalid": fieldState.invalid,
                              })}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);
                              }}
                              min="0"
                              // placeholder={t("hillJanajati")}
                              tooltip={t("hillJanajati")}
                              tooltipOptions={{
                                position: "bottom"
                              }}
                            />
                          )}
                        />
                      </div>
                     <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("hillDalit")}
                    </div>
                        <Controller
                          name="hillDalitReceivingServicesInCch"
                          control={control}
                          autoFocus
                          render={({ field, fieldState }) => (
                            <InputNumber
                              id={field.name}
                              {...field}
                              className={classNames({
                                "p-invalid": fieldState.invalid,
                              })}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);
                              }}
                              // placeholder={t("hillDalit")}
                              tooltip={t("hillDalit")}
                              tooltipOptions={{
                                position: "bottom"
                              }}
                              min="0"
                            />
                          )}
                        />
                      </div>
                     <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("teraiJanajati")}
                    </div>
                        <Controller
                          name="teraiJanjatiReceivingServicesInCch"
                          control={control}
                          render={({ field, fieldState }) => (
                            <InputNumber
                              id={field.name}
                              {...field}
                              className={classNames({
                                "p-invalid": fieldState.invalid,
                              })}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);
                              }}
                              min="0"
                              // placeholder={t("teraiJanajati")}
                              tooltip={t("teraiJanajati")}
                              tooltipOptions={{
                                position: "bottom"
                              }}
                            />
                          )}
                        />
                      </div>
                     <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("teraiMadhesiOther")}
                    </div>
                        <Controller
                          name="madhesiReceivingServicesInCch"
                          control={control}
                          render={({ field, fieldState }) => (
                            <InputNumber
                              id={field.name}
                              {...field}
                              className={classNames({
                                "p-invalid": fieldState.invalid,
                              })}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);
                              }}
                              min="0"
                              // placeholder={t("teraiMadhesiOther")}
                              tooltip={t("teraiMadhesiOther")}
                              tooltipOptions={{
                                position: "bottom"
                              }}
                            />
                          )}
                        />
                      </div>
                     <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("teraiDalit")}
                    </div>
                        <Controller
                          name="teraiDalitReceivingServicesInCch"
                          control={control}
                          autoFocus
                          render={({ field, fieldState }) => (
                            <InputNumber
                              id={field.name}
                              {...field}
                              className={classNames({
                                "p-invalid": fieldState.invalid,
                              })}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);
                              }}
                              // placeholder={t("teraiDalit")}
                              tooltip={t("teraiDalit")}
                              tooltipOptions={{
                                position: "bottom"
                              }}
                              min="0"
                            />
                          )}
                        />
                      </div>
                     <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("tharu")}
                    </div>
                        <Controller
                          name="tharuReceivingServicesInCch"
                          control={control}
                          render={({ field, fieldState }) => (
                            <InputNumber
                              id={field.name}
                              {...field}
                              className={classNames({
                                "p-invalid": fieldState.invalid,
                              })}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);
                              }}
                              min="0"
                              // placeholder={t("tharu")}
                              tooltip={t("tharu")}
                              tooltipOptions={{
                                position: "bottom"
                              }}
                            />
                          )}
                        />
                      </div>
                     <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("muslim")}
                    </div>
                        <Controller
                          name="muslimReceivingServicesInCch"
                          control={control}
                          render={({ field, fieldState }) => (
                            <InputNumber
                              id={field.name}
                              {...field}
                              className={classNames({
                                "p-invalid": fieldState.invalid,
                              })}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);
                              }}
                              min="0"
                              // placeholder={t("muslim")}
                              tooltip={t("muslim")}
                              tooltipOptions={{
                                position: "bottom"
                              }}
                            />
                          )}
                        />
                      </div>
                     <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("otherCaste")}
                    </div>
                        <Controller
                          name="otherCasteReceivingServicesInCch"
                          control={control}
                          render={({ field, fieldState }) => (
                            <InputNumber
                              id={field.name}
                              {...field}
                              className={classNames({
                                "p-invalid": fieldState.invalid,
                              })}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);
                              }}
                              min="0"
                              // placeholder={t("otherCaste")}
                              tooltip={t("otherCaste")}
                              tooltipOptions={{
                                position: "bottom"
                              }}
                            />
                          )}
                        />
                      </div>
                     <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("total")}
                    </div>
                        <Controller
                          control={control}
                          render={({ field, fieldState }) => (
                            <InputNumber
                              id={field.name}
                              {...field}
                              className={classNames({
                                "p-invalid": fieldState.invalid,
                              })}
                              value={(getValues("khasAryaReceivingServicesInCch") ? getValues("khasAryaReceivingServicesInCch") : 0)
                                + (getValues("hillJanjatiReceivingServicesInCch") ? getValues("hillJanjatiReceivingServicesInCch") : 0)
                                + (getValues("tharuReceivingServicesInCch") ? getValues("tharuReceivingServicesInCch") : 0)
                                + (getValues("hillDalitReceivingServicesInCch") ? getValues("hillDalitReceivingServicesInCch") : 0)
                                + (getValues("teraiJanjatiReceivingServicesInCch") ? getValues("teraiJanjatiReceivingServicesInCch") : 0)
                                + (getValues("madhesiReceivingServicesInCch") ? getValues("madhesiReceivingServicesInCch") : 0)
                                + (getValues("teraiDalitReceivingServicesInCch") ? getValues("teraiDalitReceivingServicesInCch") : 0)
                                + (getValues("muslimReceivingServicesInCch") ? getValues("muslimReceivingServicesInCch") : 0)
                                + (getValues("otherCasteReceivingServicesInCch") ? getValues("otherCasteReceivingServicesInCch") : 0)}
                              onChange={(e) => {
                                field.onChange(e.value);
                              }}
                              min="0"
                              disabled
                              // placeholder={t("total")}
                              tooltip={t("total")}
                              tooltipOptions={{
                                position: "bottom"
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="p-col-12 p-md-3">
                      <Controller
                        name="childReceivingServicesInCchSource"
                        control={control}
                        // rules={{ required: "Source is required" }}
                        render={({ field, fieldState }) => (
                          <Source
                            id={field.name}
                            {...field}
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                            value={field.value}
                            handleSourceState={(e) => {
                              console.log("e", e);
                              field.onChange(e);
                            }}
                            sourceOtherValue={getValues("childReceivingServicesInCchSourceOthers")}
                            handleSourceOtherState={(e) => {
                              setValue("childReceivingServicesInCchSourceOthers", e);
                            }}
                          />
                        )}
                        defaultValue={[]}
                      />
                      <div className="p-col-12 p-md-12">
                        {getFormErrorMessage("childReceivingServicesInCchSource")}
                      </div>
                    </div>
                  </div>

                  <div className="p-field p-col-12 p-md-12 ">
                    <div className="p-field p-col-12 p-md-12 ">
                      <hr
                        style={{ marginTop: "5px", borderTop: "1px dashed #cfcfcf" }}
                      ></hr>
                    </div>
                  </div>

                  <div className="p-grid p-col-12 p-md-12 ">
                    <div className="p-col-12 p-md-9">
                      <div className="p-field p-col-12 p-md-12 float-left main-label">
                        {t("totChildRehabilitatedFromCch")}
                        <i
                          className="pi pi-question-circle tooltip-style"
                          title={t("totChildRehabilitatedFromCchDesc")}
                        />
                      </div>
                      <div className="p-field p-col-12 p-md-12 float-left sub-label">
                        {t("byGender")}
                      </div>
                     <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("boys")}
                    </div>
                        <Controller
                          name="boysRehabilitatedFromCch"
                          control={control}
                          autoFocus
                          render={({ field, fieldState }) => (
                            <InputNumber
                              id={field.name}
                              {...field}
                              className={classNames({
                                "p-invalid": fieldState.invalid,
                              })}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);
                              }}
                              min="0"
                              // placeholder={t("boys")}
                              tooltip={t("boys")}
                              tooltipOptions={{
                                position: "bottom"
                              }}
                            />
                          )}
                        />
                      </div>
                     <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("girls")}
                    </div>
                        <Controller
                          name="girlsRehabilitatedFromCch"
                          control={control}
                          autoFocus
                          render={({ field, fieldState }) => (
                            <InputNumber
                              id={field.name}
                              {...field}
                              className={classNames({
                                "p-invalid": fieldState.invalid,
                              })}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);
                              }}
                              min="0"
                              // placeholder={t("girls")}
                              tooltip={t("girls")}
                              tooltipOptions={{
                                position: "bottom"
                              }}
                            />
                          )}
                        />
                      </div>
                     <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("others")}
                    </div>
                        <Controller
                          name="othersRehabilitatedFromCch"
                          control={control}
                          autoFocus
                          render={({ field, fieldState }) => (
                            <InputNumber
                              id={field.name}
                              {...field}
                              className={classNames({
                                "p-invalid": fieldState.invalid,
                              })}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);
                              }}
                              min="0"
                              // placeholder={t("others")}
                              tooltip={t("others")}
                              tooltipOptions={{
                                position: "bottom"
                              }}
                            />
                          )}
                        />
                      </div>
                     <div className="p-field p-col-3 p-md-3 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("total")}
                    </div>
                        <Controller
                          name="totalRehabilitatedFromCch"
                          control={control}
                          render={({ field, fieldState }) => (
                            <InputNumber
                              id={field.name}
                              {...field}
                              className={classNames({
                                "p-invalid": fieldState.invalid,
                              })}
                              value={(getValues("boysRehabilitatedFromCch") ? getValues("boysRehabilitatedFromCch") : 0)
                                + (getValues("girlsRehabilitatedFromCch") ? getValues("girlsRehabilitatedFromCch") : 0)
                                + (getValues("othersRehabilitatedFromCch") ? getValues("othersRehabilitatedFromCch") : 0)}
                              onChange={(e) => {
                                field.onChange(e.value);
                              }}
                              min="0"
                              disabled
                              tooltip={t("total")}
                              tooltipOptions={{
                                position: "bottom"
                              }}
                            />
                          )}
                        />
                      </div>
                      <div className="p-field p-col-12 p-md-12 float-left sub-label">
                        {t("byRehabilitation")}
                      </div>
                     <div className="p-field p-col-4 p-md-4 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("home")}
                    </div>
                        <Controller
                          name="rehabilitatedFromHome"
                          control={control}
                          autoFocus
                          render={({ field, fieldState }) => (
                            <InputNumber
                              id={field.name}
                              {...field}
                              className={classNames({
                                "p-invalid": fieldState.invalid,
                              })}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);
                              }}
                              min="0"
                              // placeholder={t("home")}
                              tooltip={t("home")}
                              tooltipOptions={{
                                position: "bottom"
                              }}
                            />
                          )}
                        />
                      </div>
                     <div className="p-field p-col-4 p-md-4 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("ojt")}
                    </div>
                        <Controller
                          name="rehabilitatedFromOjt"
                          control={control}
                          autoFocus
                          render={({ field, fieldState }) => (
                            <InputNumber
                              id={field.name}
                              {...field}
                              className={classNames({
                                "p-invalid": fieldState.invalid,
                              })}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);
                              }}
                              min="0"
                              // placeholder={t("ojt")}
                              tooltip={t("ojt")}
                              tooltipOptions={{
                                position: "bottom"
                              }}
                            />
                          )}
                        />
                      </div>
                     <div className="p-field p-col-4 p-md-4 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("outOfCommunity")}
                    </div>
                        <Controller
                          name="rehabilitatedFromOutOfCommunity"
                          control={control}
                          autoFocus
                          render={({ field, fieldState }) => (
                            <InputNumber
                              id={field.name}
                              {...field}
                              className={classNames({
                                "p-invalid": fieldState.invalid,
                              })}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);
                              }}
                              min="0"
                              // placeholder={t("outOfCommunity")}
                              tooltip={t("outOfCommunity")}
                              tooltipOptions={{
                                position: "bottom"
                              }}
                            />
                          )}
                        />
                      </div>
                     <div className="p-field p-col-4 p-md-4 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("adoption")}
                    </div>
                        <Controller
                          name="rehabilitatedFromAdoption"
                          control={control}
                          autoFocus
                          render={({ field, fieldState }) => (
                            <InputNumber
                              id={field.name}
                              {...field}
                              className={classNames({
                                "p-invalid": fieldState.invalid,
                              })}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);
                              }}
                              min="0"
                              // placeholder={t("adoption")}
                              tooltip={t("adoption")}
                              tooltipOptions={{
                                position: "bottom"
                              }}
                            />
                          )}
                        />
                      </div>
                     <div className="p-field p-col-4 p-md-4 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("fosterHome")}
                    </div>
                        <Controller
                          name="rehabilitatedFromFosterHome"
                          control={control}
                          autoFocus
                          render={({ field, fieldState }) => (
                            <InputNumber
                              id={field.name}
                              {...field}
                              className={classNames({
                                "p-invalid": fieldState.invalid,
                              })}
                              value={field.value}
                              onChange={(e) => {
                                field.onChange(e.value);
                              }}
                              min="0"
                              // placeholder={t("fosterHome")}
                              tooltip={t("fosterHome")}
                              tooltipOptions={{
                                position: "bottom"
                              }}
                            />
                          )}
                        />
                      </div>
                     <div className="p-field p-col-4 p-md-4 float-left">
                    <div className="p-field p-col-12 p-md-12 float-left sub-label">
                      {t("total")}
                    </div>
                        <Controller
                          control={control}
                          render={({ field, fieldState }) => (
                            <InputNumber
                              id={field.name}
                              {...field}
                              className={classNames({
                                "p-invalid": fieldState.invalid,
                              })}
                              value={(getValues("rehabilitatedFromHome") ? getValues("rehabilitatedFromHome") : 0)
                                + (getValues("rehabilitatedFromOjt") ? getValues("rehabilitatedFromOjt") : 0)
                                + (getValues("rehabilitatedFromOutOfCommunity") ? getValues("rehabilitatedFromOutOfCommunity") : 0)
                                + (getValues("rehabilitatedFromAdoption") ? getValues("rehabilitatedFromAdoption") : 0)
                                + (getValues("rehabilitatedFromFosterHome") ? getValues("rehabilitatedFromFosterHome") : 0)}
                              onChange={(e) => {
                                field.onChange(e.value);
                              }}
                              min="0"
                              disabled
                              tooltip={t("total")}
                              tooltipOptions={{
                                position: "bottom"
                              }}
                            />
                          )}
                        />
                      </div>
                    </div>
                    <div className="p-col-12 p-md-3">
                      <Controller
                        name="childRehabilitatedSource"
                        control={control}
                        // rules={{ required: "Source is required" }}
                        render={({ field, fieldState }) => (
                          <Source
                            id={field.name}
                            {...field}
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                            value={field.value}
                            handleSourceState={(e) => {
                              console.log("e", e);
                              field.onChange(e);
                            }}
                            sourceOtherValue={getValues("childRehabilitatedSourceOthers")}
                            handleSourceOtherState={(e) => {
                              setValue("childRehabilitatedSourceOthers", e);
                            }}
                          />
                        )}
                        defaultValue={[]}
                      />
                      <div className="p-col-12 p-md-12">
                        {getFormErrorMessage("childRehabilitatedSource")}
                      </div>
                    </div>
                  </div>

                  <div className="p-field p-col-12 p-md-12 ">
                    <div className="p-field p-col-12 p-md-12 ">
                      <hr
                        style={{ marginTop: "5px", borderTop: "1px dashed #cfcfcf" }}
                      ></hr>
                    </div>
                  </div>

                  <div className="p-grid p-col-12 p-md-12 ">
                    <div className="p-col-12 p-md-9">
                      <div className="p-field p-col-12 p-md-12 float-left main-label">
                        {t("perBudgetForChildCorrectionHome")}
                        <i
                          className="pi pi-question-circle tooltip-style"
                          title={t("perBudgetForChildCorrectionHomeDesc")}
                        />
                      </div>
                      <div className="p-field p-col-12 p-md-3 float-left">
                        <div className="p-inputgroup">
                          <Controller
                            name="budgetForChildCorrectionHome"
                            control={control}
                            autoFocus
                            render={({ field, fieldState }) => (
                              <InputNumber
                                id={field.name}
                                {...field}
                                className={classNames({
                                  "p-invalid": fieldState.invalid,
                                })}
                                value={field.value}
                                onChange={(e) => {
                                  field.onChange(e.value);
                                }}
                                min="0"
                                max="100"
                                mode="decimal"
                                minFractionDigits={2}
                                maxFracionDigits={2}
                              />
                            )}
                          />
                          <span className="p-inputgroup-addon">%</span>
                        </div>
                      </div>
                    </div>
                    <div className="p-col-12 p-md-3">
                      <Controller
                        name="budgetForChildCorrectionHomeSource"
                        control={control}
                        // rules={{ required: "Source is required" }}
                        render={({ field, fieldState }) => (
                          <Source
                            id={field.name}
                            {...field}
                            className={classNames({
                              "p-invalid": fieldState.invalid,
                            })}
                            value={field.value}
                            handleSourceState={(e) => {
                              console.log("e", e);
                              field.onChange(e);
                            }}
                            sourceOtherValue={getValues("budgetForChildCorrectionHomeSourceOthers")}
                            handleSourceOtherState={(e) => {
                              setValue("budgetForChildCorrectionHomeSourceOthers", e);
                            }}
                          />
                        )}
                        defaultValue={[]}
                      />
                      <div className="p-col-12 p-md-12">
                        {getFormErrorMessage("budgetForChildCorrectionHomeSource")}
                      </div>
                    </div>
                  </div>
                </>
              ) : (
                <></>
              )}

              {hideBtn === "No" ? (
                <>
                  {showBtn === "Yes" ? (
                    <div className="p-grid p-col-12 p-md-12">

                      <div className="p-col-12 p-md-8"></div>
                      <div className="p-col-12 p-md-2">
                        <Button label={t("save")}
                          className="p-button-sm pull-right submitBtn"
                          onClick={(e) => saveData(e)} />
                      </div>
                      <div className="p-col-12 p-md-2">
                        <Button label={t("submit")}
                          className="p-button-sm pull-right submitBtn"
                          onClick={handleSubmit(submitData)} />
                      </div>
                    </div>
                  ) :
                    <></>
                  }</>
              ) : (
                <></>
              )}

{localLevelVerifier ? (
                <div className="p-grid p-col-12 p-md-12">
                  <div className="p-col-12 p-md-8"></div>
                  <div className="p-col-12 p-md-2">
                    <Button label={t("synchronizeData")}
                      className="p-button-sm pull-right submitBtn"
                      tooltip={t("synchronizeNote")}
                      tooltipOptions={{
                        position: "bottom"
                      }}
                      onClick={() =>
                        history.push({
                          pathname: "/sims/synchronization-module"
                        })
                      }
                    />
                  </div>
                  <div className="p-col-12 p-md-2">
                    <Button label={t("revertData")}
                      className="p-button-sm pull-right submitBtn"
                      tooltip={t("revertNote")}
                      tooltipOptions={{
                        position: "bottom"
                      }}
                      onClick={() =>
                        history.push({
                          pathname: "/sims/revert-module"
                        })
                      }
                    />
                  </div>
                </div>
              ) : (
                <></>
              )}
              
            </div>
          </form>
        </div>
      </Card>
    </div>
  );
}
export default JuvenileReformHomeIndicator;
