import React, { useState } from "react";
import { Route, BrowserRouter as Router, Switch, Redirect } from "react-router-dom";
import "./App.css";
import "primereact/resources/themes/saga-blue/theme.css";
import "primereact/resources/primereact.min.css";
import "primeflex/primeflex.css";
import "primeicons/primeicons.css";
import { Image } from "primereact/image";
import SIMS from "./scafolding/assets/images/sims.jpg";
import { InputSwitch } from "primereact/inputswitch";

import dashboard from "./scafolding/components/Dashboard";
import SideBar from "../src/scafolding/components/Sidebar";

import LandingPage1 from "./landingPage/Components/LandingPage.js";
import Faq from "./landingPage/Components/Faq";

import DisabilityIDCardForm from "./IdCardForm/Components/DisabilityIDCardForm.js";
import DisabilityIDCard from "./IdCard/Components/DisabilityIDCard.js";
import DisabilityCardDetail from "./IdCardForm/Components/DisabilityCardDetail";
import SeniorCitizenDetail from "./IdCardForm/Components/SeniorCitizenDetail";
import SeniorCitizenIDCard from "./IdCard/Components/SeniorCitizenIDCard.js";
import SeniorCitizenIDCardForm from "./IdCardForm/Components/SeniorCitizenIDCardForm.js";

import UserCreationForm from "./UserCreation/Components/UserCreationForm.js";
import UserCreationWardForm from "./UserCreation/Components/UserCreationWardForm";


import CumulativeWomenGender from "./CumulativeWomenGender/Components/CumulativeWomenGender.js";
import ChildrenAdolescentModule from "./ChildrenAdolescentModule/Components/ChildrenAdolescentModule.js";
import DisabledModule from "./DisabledModule/Components/DisabledModule.js";
import SeniorCitizenModule from "./SeniorCitizenModule/Components/SeniorCitizenModule";

import ShelterHomeIndicator from "./HumanTrafficking/components/ShelterHomeIndicator";
import SewaKendraIndicator from "./HumanTrafficking/components/SewaKendraIndicator";
import ComplaintRegistration from "./HumanTrafficking/components/ComplaintRegistration";
import ChildHomeIndicator from "./ChildHome/Components/ChildHomeIndicator";
import JuvenileReformHomeIndicator from "./ChildCorrectionHome/components/JuvenileReformHomeIndicator";
import OldAgeHomeIndicator from "./OldAgeHome/components/OldAgeHomeIndicator";
import LabourMigrationIndicator from "./LabourMigration/Components/LabourMigrationIndicator";

import CreateRole from "./security/components/createRole";

import ChildHome from "./SocialService/components/ChildHome";
import ChildHomeList from "./SocialService/components/ChildHomeList";

import ShelterHome from "./SocialService/components/ShelterHome";
import ShelterHomeList from "./SocialService/components/ShelterHomeList";
import JuvenileReformHomeList from "./SocialService/components/JuvenileReformHomeList";
import OldAgeHome from "./SocialService/components/OldAgeHome";
import OldAgeHomeList from "./SocialService/components/OldAgeHomeList";

import LabourMigration from "./LabourMigration/Components/LabourMigration";

import DisabilityType from "./setup/components/DisabilityType";
import FiscalYearSetup from "./setup/components/FiscalYearSetup";

import SocialServiceRegistration from "./security/components/SocialServiceRegistration";
import RoleCreation from "./UserCreation/Components/RoleCreation";

import i18n from "../src/il8n/il8n";
import { I18nextProvider } from "react-i18next";
import LabourMigrationList from "./LabourMigration/Components/LabourMigrationList";
import DisabilityIDCardList from "./IdCard/Components/DisabilityIDCardList";
import { usePromiseTracker } from "react-promise-tracker";
import SeniorCitizenIdCardList from "./IdCard/Components/SeniorCitizenIdCardList1";

import ChildrenProvinceWiseReport from "./reports/components/ChildrenProvinceWiseReport";
import ChildrenLocalLevelWiseReport from "./reports/components/ChildrenLocalLevelWiseReport";
import WomenProvinceWiseReport from "./reports/components/WomenProvinceWiseReport";
import WomenLocalLevelWiseReport from "./reports/components/WomenLocalLevelWiseReport";
import DisabledProvinceWiseReport from "./reports/components/DisabledProvinceWiseReport";
import DisabledLocalLevelWiseReport from "./reports/components/DisabledLocalLevelWiseReport";
import SeniorCitizenProvinceWiseReport from "./reports/components/SeniorCitizenProvinceReport";
import SeniorCitizenLocalLevelWiseReport from "./reports/components/SeniorCitizenLocalLevelReport";
import LabourMigrationProvinceWiseReport from "./reports/components/LabourMigrationProvinceReport";
import LabourMigrationLocalLevelWiseReport from "./reports/components/LabourMigrationLocalLevelReport";
import ShelterHomeProvinceWiseReport from "./reports/components/ShelterHomeProvinceWiseReport";
import ShelterHomeLocalLevelReport from "./reports/components/ShelterHomeLocalLevelReport";
import ComplaintRegistrationProvinceWiseReport from "./reports/components/ComplaintRegistrationProvinceWise";
import ComplaintRegistrationLocalLevelWiseReport from "./reports/components/ComplaintRegistrationLocalLevelWise";
import IdcardProvinceWiseReport from "./reports/components/IdcardProvinceWise";
import IdcardLocalLevelWiseReport from "./reports/components/IdcardLocalLevelWise";

import ConsolidatedReport from "./reports/components/ConsolidatedReport";

//Indicator Report
import WomenAndMinoritiesReport from "./reports/components/IndicatorReport/WomenAndMinorities";
import ChildrenReport from "./reports/components/IndicatorReport/ChildrenAndAdolescent";
import DisabledReport from "./reports/components/IndicatorReport/Disabled";
import SeniorCitizenReport from "./reports/components/IndicatorReport/SeniorCitizens";
import ChildHomeReport from "./reports/components/IndicatorReport/ChildHome";
import ChildCorrectionHomeReport from "./reports/components/IndicatorReport/ChildCorrectionHome";
import OldAgeHomeReport from "./reports/components/IndicatorReport/OldAgeHome";
import ShelterHomeReport from "./reports/components/IndicatorReport/ShelterHome";
import SewaKendraReport from "./reports/components/IndicatorReport/SewaKendra";
import LabourMigrationReport from "./reports/components/IndicatorReport/LabourMigration";
import ComplaintRegistrationReport from "./reports/components/IndicatorReport/ComplaintRegistration";

import ChangePassword from "./security/components/changePassword";
import ChangeUserPassword from "./security/components/ChangeUserPassword";
import RevertModule from "./RevertModule/components/RevertModule";

import FeedbackList from "./Feedback/components/FeedbackList";
import FeedbackUpload from "./Feedback/components/FeedbackUpload";
// export default App;
import LoadingOverlay from "react-loading-overlay";
import RingLoader from "react-spinners/RingLoader";

import Auth from "./Auth";
import Layout from "./Layout";
//import ChangePassword from "./security/components/changePassword";
import ForgotPassword from "./utilities/components/ForgotPassword";
import Organization from "./security/components/organization";
import SynchronizationModule from "./security/components/synchronizationModule";
import { t } from "i18next";
import SmartDisabilityIdCard from "./IdCard/Components/SmartDisabilityIdCard";
import SmartSeniorCitizenIDCard from "./IdCard/Components/SmartSeniorCitizenIdCard";

import FirTipCaseRegistration from "./HumanTraffickingAndTransportation/TipCaseRegistration/components/FirTipCaseRegistration";
import Prosecution from "./HumanTraffickingAndTransportation/TipCaseRegistration/components/Prosecution";

import HttShelterHome from "./HumanTraffickingAndTransportation/PossibleTipCaseRegistration/components/HttShelterHome";
import HttShelterHomeOld from "./HumanTraffickingAndTransportation/PossibleTipCaseRegistration/components/HttShelterHomeOld";
import HttServiceCenter from "./HumanTraffickingAndTransportation/PossibleTipCaseRegistration/components/HttServiceCenter";
import HttServiceCenterOld from "./HumanTraffickingAndTransportation/PossibleTipCaseRegistration/components/HttServiceCenterOld";
import ChildCareHome from "./HumanTraffickingAndTransportation/PossibleTipCaseRegistration/components/ChildCareHome";
import ChildCareHomeOld from "./HumanTraffickingAndTransportation/PossibleTipCaseRegistration/components/ChildCareHomeOld";
import HttShelterHomeListNew from "./HumanTraffickingAndTransportation/PossibleTipCaseRegistration/components/HttShelterHomeListNew";
import HttServiceCenterListNew from "./HumanTraffickingAndTransportation/PossibleTipCaseRegistration/components/HttServiceCenterListNew";
import HttChildCareHomeListNew from "./HumanTraffickingAndTransportation/PossibleTipCaseRegistration/components/ChildCareHomeListNew.js";
import HttChildHomeVerificationList from "./HumanTraffickingAndTransportation/PossibleTipCaseRegistration/components/ChildHomeVerificationList.js";

import JudicialCommittee from "./GbvModule/components/JudicialCommittee";
import GbvShelterHome from "./GbvModule/components/GbvShelterHome";
import GbvShelterHomePersonal from "./GbvModule/components/ShelterHomePersonal";
import GbvServiceCenter from "./GbvModule/components/GbvServiceCenter";
import GbvServiceCenterPersonal from "./GbvModule/components/ServiceCenterPersonal";
import GbvShelterHomeList from "./GbvModule/components/GbvShelterHomeList";
import GbvServiceCenterList from "./GbvModule/components/GbvServiceCenterList";
import HttChildCareHomeView from "./HumanTraffickingAndTransportation/PossibleTipCaseRegistration/components/ChildCareHomeView.js";
import HttServiceCenterVerification from "./HumanTraffickingAndTransportation/PossibleTipCaseRegistration/components/HttServiceCenterVerification.js";
import HttShelterHomeVerification from "./HumanTraffickingAndTransportation/PossibleTipCaseRegistration/components/HttShelterHomeVerification.js";
import HttServiceCenterView from "./HumanTraffickingAndTransportation/PossibleTipCaseRegistration/components/HttServiceCenterView.js";
import HttShelterHomeView from "./HumanTraffickingAndTransportation/PossibleTipCaseRegistration/components/HttShelterHomeView.js";

import HomeLookup from "./Lookup/Components/HomeLookup.js";
import ChildCareHomeLookup from "./Lookup/Components/ChildCareHomeLookup.js";
import ServiceCenterLookup from "./Lookup/Components/ServiceCenterLookup.js";
import ShelterHomeLookup from "./Lookup/Components/ShelterHomeLookup.js";
import ChildCorrectionHomeLookup from "./Lookup/Components/ChildCorrectionHomeLookup.js";
import GbvShelterHomeVerificationList from "./GbvModule/components/GbvShelterHomeVerificationList.js";
import GbvShelterHomeView from "./GbvModule/components/GbvShelterHomeView.js";
import GbvServiceCenterVerificationList from "./GbvModule/components/GbvServiceCenterVerificationList.js";
import GbvServiceCenterView from "./GbvModule/components/GbvShelterHomeView.js";
import JuvenileReformHomeView from "./SocialService/components/JuvenileReformHomeView.js";
import JuvenileReformHomeVerification from "./SocialService/components/JuvenileReformHomeVerification.js";
import JuvenileReformHome from "./SocialService/components/JuvenileReformHome";

const App = () => {
  const { promiseInProgress } = usePromiseTracker();
  //  const [nepLanguage, setNepLanguage] = useState(i18n.language == "en" ? false : true);

  const [nepLanguage, setNepLanguage] = useState(i18n.language == "np" ? true : false);
  return (
    <>
      <I18nextProvider i18n={i18n}>
        <LoadingOverlay active={promiseInProgress} spinner={<RingLoader />}>
          <Router>
            <Switch>
              <Route exact path="/" component={LandingPage1} />
              <Route path="/sims/landingPage" component={LandingPage1} />
              <Route path="/sims/faq" component={Faq} />
              <Route path="/sims/change-user-password" component={ChangeUserPassword} />
              <Route path="/sims/forgot-password" component={ForgotPassword} />

              <div className="p-grid" style={{}}>

                <div style={{ background: "#FFF", width: "100%", minHeight: "50px;" }}>
                  <div className="p-mt-2 float-left">
                    <Image src={SIMS} className="logo1" height={70} width={70} />
                  </div>
                  <div className="p-md-6 p-mt-4 float-left" style={{ fontSize: "1.21vw" }}>
                    <h3 class="office-name" style={{ color: "#134cb7" }}>
                      {t("sims")}
                    </h3>
                  </div>
                  <div
                    className="p-col float-right p-mt-2"
                    style={{ color: "#fff", textAlign: "right", marginRight: "14px" }}
                  >
                    <p>
                      <span className="en" style={{ color: "#000" }}>
                        {" "}
                        EN{" "}
                      </span>
                      <InputSwitch
                        checked={nepLanguage}
                        onChange={(e) => {
                          setNepLanguage(e.value);
                          if (e.value) {
                            i18n.changeLanguage("np");
                          } else {
                            i18n.changeLanguage("en");
                          }
                        }}
                      />
                      <span className="np" style={{ color: "#000", paddingLeft: "15px" }}>
                        {" "}
                        ने{" "}
                      </span>
                    </p>
                  </div>
                </div>
                <div className="p-col-3 p-md-2 main-card" >
                  <SideBar />
                </div>
                <div
                  id="kycForm"
                  className="p-col-9" >
                  {/* style={{ marginLeft:' 200px'}}> */}
                  <PrivateRoute
                    path="/sims/disability-IDCard-form"
                    component={DisabilityIDCardForm}
                  />
                  <PrivateRoute
                    path="/sims/senior-citizen-detail"
                    component={SeniorCitizenDetail}
                  />

                  <PrivateRoute
                    path="/sims/disability-card-detail"
                    component={DisabilityCardDetail}
                  />
                  <PrivateRoute path="/sims/disability-IDCard" component={DisabilityIDCard} />
                  <PrivateRoute
                    path="/sims/senior-citizen-IDCard-form"
                    component={SeniorCitizenIDCardForm}
                  />
                  <PrivateRoute
                    path="/sims/senior-citizen-IDCard"
                    component={SeniorCitizenIDCard}
                  />
                  <PrivateRoute path="/sims/user" component={UserCreationForm} />
                  <PrivateRoute path="/sims/user-ward" component={UserCreationWardForm} />
                  <PrivateRoute path="/sims/role" component={RoleCreation} />
                  <PrivateRoute path="/sims/senior-citizen" component={SeniorCitizenModule} />
                  <PrivateRoute
                    path="/sims/cumulative-women-gender"
                    component={CumulativeWomenGender}
                  />
                  <PrivateRoute
                    path="/sims/children-adolescent"
                    component={ChildrenAdolescentModule}
                  />
                  <PrivateRoute path="/sims/child-home" component={ChildHome} />
                  <PrivateRoute path="/sims/child-home-list" component={ChildHomeList} />
                  <PrivateRoute path="/sims/disabled" component={DisabledModule} />

                  <PrivateRoute path="/sims/shelter-home" component={ShelterHome} />
                  <PrivateRoute path="/sims/shelter-home-list" component={ShelterHomeList} />
                  <PrivateRoute path="/sims/old-age-home" component={OldAgeHome} />
                  <PrivateRoute path="/sims/old-age-home-list" component={OldAgeHomeList} />
                  <PrivateRoute
                    path="/sims/shelter-home-indicator"
                    component={ShelterHomeIndicator}
                  />
                  <PrivateRoute
                    path="/sims/sewa-kendra-indicator"
                    component={SewaKendraIndicator}
                  />
                  <PrivateRoute path="/sims/child-home-indicator" component={ChildHomeIndicator} />
                  <PrivateRoute
                    path="/sims/old-age-home-indicator"
                    component={OldAgeHomeIndicator}
                  />
                  <PrivateRoute
                    path="/sims/complaint-registration"
                    component={ComplaintRegistration}
                  />
                  <PrivateRoute
                    path="/sims/labour-migration-indicator"
                    component={LabourMigrationIndicator}
                  />
                  <PrivateRoute path="/sims/labour-migration" component={LabourMigration} />
                  <PrivateRoute
                    path="/sims/labour-migration-list"
                    component={LabourMigrationList}
                  />

                  <PrivateRoute exact path="/sims/dashboard" component={dashboard} />

                  <PrivateRoute path="/sims/disability-type-setup" component={DisabilityType} />
                  <PrivateRoute path="/sims/setup" component={FiscalYearSetup} />

                  <PrivateRoute path="/nta/role" component={CreateRole} />
                  <PrivateRoute
                    path="/sims/social-service-registration"
                    component={SocialServiceRegistration}
                  />
                  {/* Security */}
                  {/* <PrivateRoute path="/nta/organization" component={CreateOrganization} />
                <PrivateRoute path="/nta/user" component={CreateUser} />
                <PrivateRoute path="/sims/role" component={CreateRole} />
                <PrivateRoute path="/sims/change-password" component={ChangePassword} />
                <PrivateRoute path="/sims/assign-module" component={AssignModule} /> */}
                  <PrivateRoute
                    path="/sims/disability-IDCard-list"
                    component={DisabilityIDCardList}
                  />
                  <PrivateRoute
                    path="/sims/senior-citizen-IDCard-list"
                    component={SeniorCitizenIdCardList}
                  />

                  <PrivateRoute
                    path="/sims/children-province-wise"
                    component={ChildrenProvinceWiseReport}
                  />
                  <PrivateRoute
                    path="/sims/children-local-level-wise"
                    component={ChildrenLocalLevelWiseReport}
                  />
                  <PrivateRoute
                    path="/sims/women-province-wise"
                    component={WomenProvinceWiseReport}
                  />
                  <PrivateRoute
                    path="/sims/women-local-level-wise"
                    component={WomenLocalLevelWiseReport}
                  />
                  <PrivateRoute
                    path="/sims/disabled-province-wise"
                    component={DisabledProvinceWiseReport}
                  />
                  <PrivateRoute
                    path="/sims/disabled-local-level-wise"
                    component={DisabledLocalLevelWiseReport}
                  />
                  <PrivateRoute
                    path="/sims/senior-citizen-province-wise"
                    component={SeniorCitizenProvinceWiseReport}
                  />
                  <PrivateRoute
                    path="/sims/senior-citizen-local-level-wise"
                    component={SeniorCitizenLocalLevelWiseReport}
                  />
                  <PrivateRoute
                    path="/sims/labour-migration-province-wise"
                    component={LabourMigrationProvinceWiseReport}
                  />
                  <PrivateRoute
                    path="/sims/labour-migration-local-level-wise"
                    component={LabourMigrationLocalLevelWiseReport}
                  />
                  <PrivateRoute
                    path="/sims/shelter-home-province-wise"
                    component={ShelterHomeProvinceWiseReport}
                  />
                  <PrivateRoute
                    path="/sims/shelter-home-local-level-wise"
                    component={ShelterHomeLocalLevelReport}
                  />
                  <PrivateRoute
                    path="/sims/complaint-registration-province-wise"
                    component={ComplaintRegistrationProvinceWiseReport}
                  />
                  <PrivateRoute
                    path="/sims/complaint-registration-local-level-wise"
                    component={ComplaintRegistrationLocalLevelWiseReport}
                  />
                  <PrivateRoute
                    path="/sims/id-card-province-wise"
                    component={IdcardProvinceWiseReport}
                  />
                  <PrivateRoute
                    path="/sims/id-card-local-level-wise"
                    component={IdcardLocalLevelWiseReport}
                  />

                  <PrivateRoute path="/sims/report/consolidated" component={ConsolidatedReport} />

                  {/* Indicator report */}
                  <PrivateRoute
                    path="/sims/report/women-minorities"
                    component={WomenAndMinoritiesReport}
                  />
                  <PrivateRoute path="/sims/report/children" component={ChildrenReport} />
                  <PrivateRoute path="/sims/report/disabled" component={DisabledReport} />
                  <PrivateRoute path="/sims/report/senior-citizen" component={SeniorCitizenReport} />
                  <PrivateRoute path="/sims/report/child-home" component={ChildHomeReport} />
                  <PrivateRoute
                    path="/sims/report/child-correction-home"
                    component={ChildCorrectionHomeReport}
                  />
                  <PrivateRoute path="/sims/report/old-age-home" component={OldAgeHomeReport} />
                  <PrivateRoute path="/sims/report/shelter-home" component={ShelterHomeReport} />
                  <PrivateRoute path="/sims/report/sewa-kendra" component={SewaKendraReport} />
                  <PrivateRoute path="/sims/report/labour-migration" component={LabourMigrationReport} />
                  <PrivateRoute
                    path="/sims/report/complaint-registration"
                    component={ComplaintRegistrationReport}
                  />
                  {/*  Indicator report */}

                  <PrivateRoute path="/sims/change-password" component={ChangePassword} />
                  <PrivateRoute path="/sims/organization-list" component={Organization} />
                  <Route path="/sims/synchronization-module" component={SynchronizationModule} />
                  <PrivateRoute path="/sims/revert-module" component={RevertModule} />
                  <PrivateRoute
                    path="/sims/disability-IDCard-smart"
                    component={SmartDisabilityIdCard}
                  />
                  <PrivateRoute
                    path="/sims/senior-citizen-IDCard-smart"
                    component={SmartSeniorCitizenIDCard}
                  />


                  <PrivateRoute
                    path="/sims/feedback-upload"
                    component={FeedbackUpload}
                  />
                  <PrivateRoute
                    path="/sims/feedback-list"
                    component={FeedbackList}
                  />

                  <PrivateRoute path="/sims/fir-tip-case-registration" component={FirTipCaseRegistration} />
                  <PrivateRoute path="/sims/prosecution" component={Prosecution} />

                  <PrivateRoute
                    path="/sims/htt-shelter-home"
                    component={HttShelterHome}
                  />
                  <PrivateRoute
                    path="/sims/htt-shelter-home-old"
                    component={HttShelterHomeOld}
                  />
                  <PrivateRoute
                    path="/sims/htt-shelter-home-list-new"
                    component={HttShelterHomeListNew}
                  />
                  <PrivateRoute
                    path="/sims/htt-child-care-home"
                    component={ChildCareHome}
                  />
                  <PrivateRoute
                    path="/sims/htt-child-care-home-old"
                    component={ChildCareHomeOld}
                  />
                  <PrivateRoute
                    path="/sims/htt-child-care-home-list-new"
                    component={HttChildCareHomeListNew}
                  />
                  <PrivateRoute
                    path="/sims/htt-service-center"
                    component={HttServiceCenter}
                  />
                  <PrivateRoute
                    path="/sims/htt-service-center-old"
                    component={HttServiceCenterOld}
                  />
                  <PrivateRoute
                    path="/sims/htt-service-center-list-new"
                    component={HttServiceCenterListNew}
                  />
                  <PrivateRoute
                    path="/sims/htt-child-home-verification"
                    component={HttChildHomeVerificationList}
                  />
                  <PrivateRoute
                    path="/sims/htt-child-home-view"
                    component={HttChildCareHomeView}
                  />
                  <PrivateRoute
                    path="/sims/htt-service-center-verification"
                    component={HttServiceCenterVerification}
                  />
                  <PrivateRoute
                    path="/sims/htt-service-center-view"
                    component={HttServiceCenterView}
                  />
                  <PrivateRoute
                    path="/sims/htt-shelter-home-verification"
                    component={HttShelterHomeVerification}
                  />
                  <PrivateRoute
                    path="/sims/htt-shelter-home-view"
                    component={HttShelterHomeView}
                  />

                  <PrivateRoute path="/sims/judicial-committee" component={JudicialCommittee} />
                  <PrivateRoute path="/sims/gbv-shelter-home" component={GbvShelterHome} />
                  <PrivateRoute path="/sims/gbv-shelter-home-personal" component={GbvShelterHomePersonal} />
                  <PrivateRoute path="/sims/gbv-shelter-home-verification" component={GbvShelterHomeVerificationList} />
                  <PrivateRoute path="/sims/gbv-shelter-home-view" component={GbvShelterHomeView} />
                  <PrivateRoute path="/sims/gbv-service-center" component={GbvServiceCenter} />
                  <PrivateRoute path="/sims/gbv-service-center-personal" component={GbvServiceCenterPersonal} />
                  <PrivateRoute path="/sims/gbv-shelter-home-list" component={GbvShelterHomeList} />
                  <PrivateRoute path="/sims/gbv-service-center-list" component={GbvServiceCenterList} />
                  <PrivateRoute path="/sims/gbv-service-center-view" component={GbvServiceCenterView} />
                  <PrivateRoute path="/sims/gbv-service-center-verification" component={GbvServiceCenterVerificationList} />

                  <PrivateRoute path="/sims/home-lookup" component={HomeLookup} />
                  <PrivateRoute path="/sims/shelter-home-lookup" component={ShelterHomeLookup} />
                  <PrivateRoute path="/sims/service-center-lookup" component={ServiceCenterLookup} />
                  <PrivateRoute path="/sims/child-care-home-lookup" component={ChildCareHomeLookup} />
                  <PrivateRoute path="/sims/child-correction-home-lookup" component={ChildCorrectionHomeLookup} />

                  <PrivateRoute
                    path="/sims/juvenile-reform-home-indicator"
                    component={JuvenileReformHomeIndicator}
                  />
                  <PrivateRoute path="/sims/juvenile-reform-home" component={JuvenileReformHome} />
                  <PrivateRoute
                    path="/sims/juvenile-reform-home-list"
                    component={JuvenileReformHomeList}
                  />
                  <PrivateRoute
                    path="/sims/juvenile-reform-home-verification"
                    component={JuvenileReformHomeVerification}
                  />
                  <PrivateRoute
                    path="/sims/juvenile-reform-home-view"
                    component={JuvenileReformHomeView}
                  />

                </div>
              </div>
            </Switch>
          </Router>
        </LoadingOverlay>
      </I18nextProvider>
    </>
  );
};
export default App;

function PrivateRoute({ component: Component, ...restOfProps }) {
  return (
    <Route
      {...restOfProps}
      render={(props) =>
        Auth.getAuth() ? (
          <Layout {...props} />
        ) : (
          <Redirect
            to={{
              pathname: "/",
            }}
          />
        )
      }
    />
  );
}
